<template>
  <v-main class="natural lighten-3">
    <v-container fluid>
      <v-row >
        <v-col cols="12" v-for="(item,index) in items" :key="index">
       <RecordCard :name="item.name_th" :cid="item.id_number" :timestamp="item.createDate" :formTypeName="item.formTypeName" mode='done'/>
        </v-col>
      </v-row>
    </v-container>
     <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import RecordCard from "@/components/recorddaily/RecordCard";
import {getFormResult} from "@/api/"

export default {
  components: {
    RecordCard,
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  methods: {
    fetchData() {
        this.role = this.$offlineStorage.get('role')
        const site = this.$offlineStorage.get("site");
        const tenant = this.$offlineStorage.get("tenant");
      if (this.isOnline){
           this.loading = true
      getFormResult({tenantId: tenant.tenantId ,siteId:site.siteId,offset:0,limit:100 },message=>{
        if(message.data.code===1){
         
          this.$offlineStorage.set('resultformlist',message.data.result)
          this.items= message.data.result
          //console.log(this.items)
        }
        this.loading = false
      },error=>{
        console.log(error)
         this.loading = false
      })
      }else{
         this.items =  this.$offlineStorage.get('resultformlist')
          this.loading = false
      }
    }
  },
   mounted () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
   }
};
</script>

<style>
</style>